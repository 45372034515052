<template>
  <div class="footer">
    <div class="full-container" v-if="$route.meta.showFooterBtn">
      <div class="content">
        <h1>物联改变世界，创新引领未来</h1>
        <div class="buttons">
          <div>
            <a :href="$route.path == '/production/view' ? 'https://view.jetlinks.cn/' : 'https://demo.jetlinks.cn/'" target="_blank">免费试用</a>
          </div>
          <div><router-link to="/contact_us">联系我们</router-link></div>
        </div>
      </div>
    </div>
    <div class="container">
      <a-row>
        <a-col :span="6">
          <img src="@/assets/logo-black.svg" alt="">
          <h1>JetLinks</h1>
          <div class="contract">
            <img src="@/assets/images/footer/wechat.png" alt="">
            <a :href="groupLink" target="_blank">
              <img src="@/assets/images/footer/QQ.png" alt="" />
            </a>
            <a href="https://github.com/jetlinks/jetlinks-community" target="_blank">
              <img src="@/assets/images/footer/github.png" alt=""/>
            </a>
          </div>
          <p>客服群QQ：<a :href="groupLink" target="_blank">{{newGroupId}}</a></p>
        </a-col>
        <a-col :span="18">
          <div class="menus">
            <ul class="menu-list">
              <li>产品</li>
              <li><router-link to="/production/iot">JetLinks-lot</router-link></li>
              <!-- <li><router-link to="/production/edge">JetLinks-edge</router-link></li> -->
              <li><router-link to="/production/view">JetLinks-view</router-link></li>
            </ul>
            <ul class="menu-list" v-if="false">
              <li>解决方案</li>
              <li><router-link to="/solution/industry">智慧工业</router-link></li>
              <li><router-link to="/solution/city">智慧城市</router-link></li>
              <li><router-link to="/solution/construction">智慧工地</router-link></li>
            </ul>
            <ul class="menu-list">
              <li><a>文档</a></li>
              <li><a href="http://doc.jetlinks.cn/Product_introduction/Basic_concepts2.2.html" target="_blank">基本概念</a></li>
              <li><a href="http://doc.jetlinks.cn/install-deployment/start-with-source.html" target="_blank">安装部署</a></li>
              <li><a href="http://doc.jetlinks.cn/Device_access/Create_product3.1.html" target="_blank">设备接入</a></li>
              <li><a href="http://doc.jetlinks.cn/dev-guide/start.html" target="_blank">开发手册</a></li>
              <li><a href="http://doc.jetlinks.cn/device_management/product4.1.html" target="_blank">操作手册</a></li>
            </ul>
            <ul class="menu-list">
              <li>公司</li>
              <li><router-link to="/company">关于我们</router-link></li>
              <li><router-link to="/contact_us">联系我们</router-link></li>
            </ul>
          </div>
        </a-col>
      </a-row>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="copyright">版权：Copyright © JetLinks，All Rights Reserved.打造开箱即用的物联网平台</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="copyright">备案：渝ICP备19017719号-1</a>
    </div>
  </div>
</template>

<script>
import { newGroupId, groupLink } from "@/utils/consts";

export default {
  name: '',
  data() {
    return {
      newGroupId,
      groupLink
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .footer {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    white-space: nowrap;
    .full-container {
      height: 20.5rem;
      position: relative;
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(108, 134, 172, 0.82);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        background: url('~assets/images/footer/footer-bg1.svg') no-repeat, center center/100% 100%;
      }
      &::after{
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(108, 134, 172, 0.82);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
      .content{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
      h1{
        font-size: 3rem;
        color: #FFFFFF;
        margin-bottom: 3.125rem;
      }
      .buttons{
        display: flex;
        div{
          transition: all .1s linear;
          a{
            color: #FFFFFF;
            font-size: 1.125rem;
          }
          &:hover{
            transform: translateY(-8px);
          }
          cursor: pointer;
          width: 10rem;
          height: 3rem;
          line-height: 3rem;
          text-align: center;
          margin: 0 1rem;
          border-radius: 2px;
          color: #FFFFFF;
          border: none;
          &:first-child{
            background: linear-gradient(266.72deg, #DF85FF 8.93%, #C343E3 84.54%);
            box-shadow: 0px 2px 10px rgba(65, 22, 99, 0.2);
          }
          &:last-child{
            background: linear-gradient(265.95deg, #58AFEE -0.24%, #196CE8 96.15%);
            box-shadow: 0px 4px 10px rgba(22, 59, 93, 0.2);
          }
        }
      }
    }
    .container {
      padding: 7.125rem 0;
      position: relative;
      .ant-row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        h1{
          font-size: 4rem;
          font-weight: 500;
        }
        .contract{
          margin: 1.5rem 0;
          img {
            margin-right: 1rem;
            transition: all .1s linear;
            &:hover{
              transform: translateY(-8px);
            }
          }
        }
        p{
          font-size: 1.25rem;
          color: #000000;
          margin-bottom: 1.5rem;
        }
        .menus {
          display: flex;
          justify-content: space-around;
          .menu-list{
            li{
              font-size: 1.125rem;
              margin: 0.75rem 0;
              &:first-child{
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1.125rem;
              }
              &:nth-child(n-1) {
                cursor: pointer;
              }
            }
          }
        }
      }
      .copyright {
        color: rgba(0, 0, 0, 0.6);
        margin-top: 1.25rem;
        display: block;
      }
    }
  }
</style>