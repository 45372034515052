export const newGroupId = '572077464'
export const groupLink = 'https://qm.qq.com/q/XwEr1w4t0c'

export const qqGroup = [
  {
    name: `群6：${newGroupId}`,
    link: groupLink,
    full: false
  },
  {
    name: '群5：554591908（已满）',
    link: 'https://qm.qq.com/cgi-bin/qm/qr?k=jiirLiyFUecy_gsankzVQ-cl6SrZCnv9&&jump_from=webapi',
    full: true
  },
  {
    name: '群4：780133058（已满）',
    link: '',
    full: true
  },
  {
    name: '群3：647954464（已满）',
    link: '',
    full: true
  },
  {
    name: '群2：324606263（已满）',
    link: '',
    full: true
  },
  {
    name: '群1：2021514（已满）',
    link: '',
    full: true
  }
]
