<template>
  <div class="version">
    <jet-card title="JetLinks授权版本" :titleType="2" :fullWidth="true">
      <div class="version-jetlinks">
        <div class="community">
          <h2>社区版</h2>
          <p>可通过MQTT、HTTP、TCP网络协议接入设备并支持自定义消息协议。</p>
          <h4>核心功能</h4>
          <ul class="column-list">
            <li>
              <span></span>
              <p>设备管理,设备接入</p>
            </li>
            <li>
              <span></span>
              <p>多消息协议支持</p>
            </li>
            <li>
              <span></span>
              <p>规则引擎-场景联动</p>
            </li>
            <li>
              <span></span>
              <p>告警中心支持</p>
            </li>
            <li>
              <span></span>
              <p>系统监控,数据统计</p>
            </li>
            <li>
              <span></span>
              <p>邮件、短信、语音、WebHook、钉钉、企业微信通知支持</p>
            </li>
            <li>
              <span></span>
              <p>MQTT，TCP（TLS）、HTTP</p>
            </li>
            <li>
              <span></span>
              <p>系统日志、访问日志</p>
            </li>
            <li>
              <span></span>
              <p>关系配置支持</p>
            </li>
          </ul>
          <p class="attention-text">仅支持单机使用，用于自有项目，禁止售卖源代码。</p>
          <a-button type="success">
            <a href="https://github.com/jetlinks/jetlinks-community" target="_blank" rel="noopener noreferrer">免费试用
              (Goto Github)</a>
          </a-button>
          <a-button type="success" ghost>
            <a href="https://demo.jetlinks.cn/" target="_blank" rel="noopener noreferrer">快速上手</a>
          </a-button>
        </div>
        <div class="company">
          <h2>企业版</h2>
          <p>提供异常熔断处理、脚本访问安全控制；支持数据权限、微服务架构、水平扩容等众多企业级功能。并提供线上一对一技术支撑和定制化开发支持。</p>
          <h4>除社区版所有功能外，它还有如下功能：</h4>
          <ul class="row-list higher">
            <li>
              <span></span>
              <p>UDP，CoAP（DTLS）；WebSocket(TLS)</p>
            </li>
            <li>
              <span></span>
              <p>支持转发设备数据到RabbitMQ,Kafka</p>
            </li>
            <li>
              <span></span>
              <p>Geo地理位置支持</p>
            </li>
            <li>
              <span></span>
              <p>API 服务、API客户端、SSO单点登陆</p>
            </li>
            <li>
              <span></span>
              <p>规则引擎-可视化设计器</p>
            </li>
            <li>
              <span></span>
              <p>数据权限控制</p>
            </li>
            <li>
              <span></span>
              <p>集群支持</p>
            </li>
            <li>
              <span></span>
              <p>微服务架构</p>
            </li>
            <li>
              <span></span>
              <p>异常熔断处理</p>
            </li>
            <li>
              <span></span>
              <p>脚本语言安全控制</p>
            </li>
          </ul>
          <h4>付费选配模块：</h4>
          <ul class="row-list">
            <li>
              <span></span>
              <p>阿里云协议适配</p>
            </li>
            <li>
              <span></span>
              <p>阿里云平台接入</p>
            </li>
            <li>
              <span></span>
              <p>小度平台接入</p>
            </li>
            <li>
              <span></span>
              <p>电信CTWing平台接入</p>
            </li>
            <li>
              <span></span>
              <p>移动OneNet平台接入</p>
            </li>
            <li>
              <span></span>
              <p>GBT/28181视频设备接入</p>
            </li>
            <li>
              <span></span>
              <p>OPC UA数采</p>
            </li>
            <li>
              <span></span>
              <p>Modbus/tcp 数采</p>
            </li>
            <li>
              <span></span>
              <p>2D组态</p>
            </li>
            <li>
              <span></span>
              <p>物联网卡管理</p>
            </li>
          </ul>
          <p class="attention-text">不限项目数量</p>
          <a-button type="primary">
            <a :href="groupLink"
              target="_blank" rel="noopener noreferrer">
              添加QQ群{{newGroupId}}联系商务
            </a>
          </a-button>
          <a-button type="primary" ghost>
            <a href="https://demo.jetlinks.cn/" target="_blank" rel="noopener noreferrer">快速上手</a>
          </a-button>
        </div>
        <div class="mirror">
          <h2>镜像版</h2>
          <p>经济实用，可直接部署使用，快速开启项目。</p>
          <h4>核心功能</h4>
          <ul class="column-list">
            <li>
              <span></span>
              <p>UDP，CoAP（DTLS）；WebSocket(TLS)</p>
            </li>
            <li>
              <span></span>
              <p>支持转发设备数据到RabbitMQ,Kafka</p>
            </li>
            <li>
              <span></span>
              <p>Geo地理位置支持</p>
            </li>
            <li>
              <span></span>
              <p>API 服务、API客户端、SSO单点登陆</p>
            </li>
            <li>
              <span></span>
              <p>规则引擎-可视化设计器</p>
            </li>
            <li>
              <span></span>
              <p>数据权限控制</p>
            </li>
            <li>
              <span></span>
              <p>集群支持</p>
            </li>
            <li>
              <span></span>
              <p>异常熔断处理</p>
            </li>
            <li>
              <span></span>
              <p>脚本语言安全控制</p>
            </li>
          </ul>
          <p class="attention-text">授权单项目使用，禁止反编译</p>
          <a-button type="warn">
            <a :href="groupLink"
              target="_blank" rel="noopener noreferrer">
              添加QQ群{{newGroupId}}联系商务
            </a>
          </a-button>
          <a-button type="warn" ghost>
            <a href="https://demo.jetlinks.cn/" target="_blank" rel="noopener noreferrer">快速上手</a>
          </a-button>
        </div>
      </div>
      <div class="permission">
        <h2><img src="@/assets/images/production/jetlinksiot/vector.png" alt="">权限限制</h2>
        <p class="content">1、可基于JetLinks源代码二次开发【二次开发指结合甲方/最终客户方个性化需求，为实现项目交付所进行的定制化修改/功能性扩展等开发方式】</p>
        <p class="content">2、源代码授权公对公/一对一，仅允许被授权方以项目形式，进行二次开发做成解决方案并整体封装后交付，不得单独交付JetLinks源代码或发布成同类型物联网基础平台销售。</p>
        <p class="content">3、被授权方不得替换乙方提供Java源代码中含JetLinks的相关标识：Java类中package代码包名、Maven中的groupId。【不影响项目使用】</p>
        <p class="attention-text">违约追责，侵权必究，一经发现，永不授权！ </p>
      </div>
    </jet-card>
  </div>
</template>

<script>
import { newGroupId, groupLink } from "@/utils/consts";

export default {
  name: '',
  data() {
    return {
      newGroupId,
      groupLink
    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
:deep(.version ){
  width: 78% !important;
}
.version-jetlinks {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #666666;

  div {
    height: 66.25rem;
    margin: 1rem 0 0 0;
    padding: 1.5625rem 1.5rem 2.875rem 1.5rem;
    background-color: #FFFFFF;
    font-size: 1.125rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 2.25rem;
    }

    h4 {
      margin-top: 0.5rem;
    }

    p {
      color: #333333;
      margin-bottom: 1rem;
    }
  }

  .community {
    width: 26rem;
    height: 58.625rem;
    border-top: 14px solid #23ACB4;
    box-shadow: 0px 0px 16px #EFF3F6;
    border-radius: 10px 10px 0 0;

    h2 {
      color: #23ACB4;
    }

    span {
      background-color: #B3CDCF;
    }
  }

  .mirror {
    width: 26rem;
    height: 58.625rem;
    border-top: 14px solid #F9A930;
    box-shadow: 0px 0px 16px #EFF3F6;
    border-radius: 10px 10px 0 0;

    h2 {
      color: #F9A930;
    }

    span {
      background-color: rgba(241, 215, 176, 1);
    }
  }


  .company {
    height: 61rem;
    box-shadow: 0px 0px 16px #EFF3F6;
    border-top: 14px solid #0E6EFF;
    border-radius: 10px 10px 0 0;
    width: 36.75rem;
    h2 {
      color: #0E6EFF;
    }

    .row-list {
      &.higher{
        li{
          margin-bottom: 0.5rem;
        }
      }
      li {
        min-width: 50%;
        margin-top: 1rem;

        &:nth-child(n+12) {
          min-width: 60%;
        }

        span {
          background-color: #D7DEFA;
        }
      }
    }
  }

  .ant-btn {
    background-color: #3055EA;
    border-color: #3055EA;
    margin-right: 1rem;
    font-size: 0.875rem;
    &.ant-btn-background-ghost {
      color: #3055EA;
    }
  }
}
.permission {
    padding: 1.5625rem 1.8125rem 2.875rem 3rem;
    margin: 1.5rem 12.5rem;
    box-shadow: 0px 0px 16px #EFF3F6;
    position: relative;
    p{
      font-size: 1.125rem;
    }
    h2{
      text-align: center;
      margin-bottom: 2rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-right: 0.875rem;
      }
    }
    .content {
      margin-bottom: 3rem;
      color: rgba(11, 17, 69, 1) !important
    }
  }
  
  .attention-text {
    color: #E50012 !important;
    font-weight: 500;
    font-size: 0.875rem;
    margin-top: 2.125rem;
    margin-bottom: 2.5rem;
  }
</style>