<template>
  <div class="contact-us full-container">
    <h3>联系我们</h3>
    <ul>
      <li>
        <img src="@/assets/images/company/qq.png" alt="">
        <div>
          <h4>技术交流Q群</h4>
          <p v-for="item in qqGroup" :key="item.name" style="color: rgba(11, 17, 69, 0.6);">
            <a v-if="!item.full" :href="item.link" target="_blank">{{item.name}}</a>
            <span v-else>{{item.name}}</span>
          </p>
        </div>
      </li>
      <li>
        <img src="@/assets/images/company/qq.png" alt="">
        <div>
          <h4>商务合作官方Q群</h4>
          <p>
            <a :href="groupLink">{{ newGroupId }}</a>
          </p>
        </div>
      </li>
      <li>
        <img src="@/assets/images/company/email.png" alt="">
        <div>
          <h4>商业合作</h4>
          <p>网关可跨硬件平台</p>
        </div>
      </li>
      <li>
        <img src="@/assets/images/company/address.png" alt="">
        <div>
          <h4>联系地址</h4>
          <p>中国重庆市南岸区玉马路8号中国智谷智能创新产业园</p>
        </div>
      </li>
    </ul>
    <div class="map-container" id="mapContainer"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import {qqGroup, newGroupId, groupLink} from "@/utils/consts";
export default {
  name: '',
  data() {
    return {
      qqGroup,
      newGroupId,
      groupLink
    }
  },
  components: {
    
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: 'e13f7e84d35c21190fdf30345d0a5abc',
        version: '2.0',
        plugins: ['']
      }).then(AMap => {
        this.map = new AMap.Map('mapContainer', {
          viewMode: '2d',
          zoom: 15,
          center: [106.638708,29.48795]
        })
        this.marker = new AMap.Marker({
           position: new AMap.LngLat(106.638708,29.48795),
           title: '中国智谷（重庆）科技园'
        })
        this.map.add(this.marker)
      })
     },
  },
}
</script>
<style scoped lang="less">
  .contact-us {
    background-color: #F3F5FB;
    padding: 11.375rem 22rem 1.5rem 22rem;
    position: relative;
    h3{
      font-weight: 700;
      font-family: 'roboto';
      font-size: 2rem;
    }
    ul{
      margin-top: 3.125rem;
      li{
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 3.5rem;
        img{
          margin-right: 1.5rem;
        }
        h4{
          margin-top: 0.5rem;
          font-size: 1.5rem;
        }
        p{
          font-size: 1.125rem;
          color: #0B1145;
        }
      }
    }
    .map-container{
      position: absolute;
      width: 49.25rem;
      height: 29.6875rem;
      top: 12rem;
      left: 50rem;
    }
  }
</style>